.page-loader__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.page-loader__box {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 10px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 2px 4px rgba(0, 0, 0, 0.18);
  width: 75vw;
  transition: all 0.3s ease;
}

.page-loader__box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.page-loader__main-content {
  margin-top: 20px;
}

.page-loader__main-content-title {
  font-size: 23px;
  text-align: center;
  color: var(--kelly-green);
  font-weight: 400;
  margin-bottom: 2.3rem;
  line-height: 1;
}

.page-loader__main-content-text {
  font-size: 16px;
  color: var(--blueGray);
  margin-bottom: 2rem;
  display: list-item;
  list-style-type: disc;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .page-loader__container {
    flex-direction: row;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .page-loader__box {
    background-color: var(--white);
  }

  .page-loader__main-content-text {
    margin-left: 1.3em;
  }

  .page-loader__box-size {
    width: 70%;
    min-height: 280px;
  }

  .page-loader__main-content-title {
    font-size: 1.8rem;
  }

  .page-loader__main-content-text {
    font-size: 1.6rem;
  }
}
