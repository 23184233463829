.main-form {
  width: 100%;
  box-sizing: border-box;
  margin-top: 1em;
}

.main-form__tag {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.main-form__title {
  color: var(--charcoal);
  font-size: 19px;
  text-align: center;
  text-shadow: none;
  margin-top: 2px;
  margin-bottom: 4px;
}

.main-form__subtitle {
  color: var(--kelly-green);
  font-size: 15px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 0;
}

.main-form__step-container {
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.main-form__step-info {
  font-size: 15px;
  color: var(--kelly-green);
}

.main-form__img-container {
  display: flex;
  justify-content: center;
}

.main-form__img {
  height: 60px;
  margin-top: 5em;
}

.main-form__list {
  margin: 0;
  padding: 1em 0 0 1em;
  list-style-position: outside;
}

.main-form__list li {
  color: black;
  margin: 0.5em 0;
  list-style-type: disc;
  padding: 0;
  list-style-position: outside;
  font-weight: 600;
}

.main-form__list {
  margin: 0;
  padding: 3rem;
  list-style-position: outside;
}

@media (min-width: 576px) {
  .main-form {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .main-form {
    padding: 1em 1em 1em 5em;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .main-form__list {
    margin: 0;
    padding: 1em 0 0 1em;
    list-style-position: outside;
  }

  .main-form__list li {
    color: #fff;
    margin: 0.5em 0;
    list-style-type: disc;
    padding: 0;
    list-style-position: outside;
    font-weight: 600;
  }

  .main-form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .main-form__title {
    color: var(--white);
    font-size: 54px;
    font-weight: 900;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
  }

  .main-form__subtitle {
    color: var(--white);
    font-size: 26px;
    line-height: 38px;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }

  .main-form__step-container {
    width: 460px;
    padding: 20px 40px 30px 40px;
  }

  .main-form__step-info {
    text-align: left;
    font-size: 18px;
    line-height: 38px;
  }

  .main-form__img-container {
    justify-content: right;
  }
}
