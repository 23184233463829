.content-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--kelly-green);
  padding: 0.4rem;
}

.content-modal__title {
  color: var(--white);
  font-size: 1.5rem;
}

.content-modal__close-btn-icon {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--white);
}

.content-modal__close-btn {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: var(--dim-gray);
}

.content-modal__body {
  text-align: justify;
  padding: 1rem;
}

.content-modal__footer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .content-modal__body {
    color: var(--dark-gray);
  }
}

/* STYLES FOR GENERATED HTML*/
.content-modal__body {
  padding: 20px;
  color: var(--dark-navy);
  font-family: 'Montserrat', sans-serif;
}

.content-modal__body ul {
  list-style-type: circle;
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-bottom: 10px;
  font-weight: 400;
}

.content-modal__body li {
  margin: 5px;
}

.content-modal__body {
  text-align: left;
  background-color: var(--white);
  padding: 16px;
}

.content-modal__body h1 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--black);
}

.content-modal__body h2 {
  font-size: 1.53125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

.content-modal__body h3 {
  font-size: 1.3125rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

.content-modal__body h4 {
  font-size: 1.09375rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
  color: var(--black);
}

.content-modal__body p {
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}

.content-modal__body table,
th,
td {
  border: 1px solid var(--black);
  border-collapse: collapse;
  font-size: 12px;
  text-align: left;
  padding: 5px;
  color: var(--slate-gray);
  line-height: 1.4em;
  margin-bottom: 10px;
  font-weight: 400;
}

.content-modal__body th {
  color: var(--slate-gray);
  font-weight: 700;
}

.content-modal__body ul li {
  list-style-type: disc;
}

.content-modal__body ol {
  font-size: 14px;
  line-height: 1.5em;
  color: var(--slate-gray);
  margin-bottom: 10px;
  font-weight: 400;
}

.innerPrivacyPolicy {
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
}